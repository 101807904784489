// Optionally override some settings
// Import fonts
@import "./typography/open_sans.css";

@import "./typography/poppins.css";

// Import icons
@import "./icons/font_awesome.min.css";

body, html{
    height:100%
}